import useBrowserApiHelpers from '@/composables/use-browser-api-helpers';
/**
 * Convert a localstorage key from JSON to JavaScript object.
 *
 * @param {string} key - The localstorage key to target.
 *
 * @return {object} - localstorage as an object.
 */
export default function parsedLocalStorage(key) {
  try {
    const { localStorageAvailable } = useBrowserApiHelpers();
    if (localStorageAvailable()) {
      if (localStorage[key] !== undefined) {
        return JSON.parse(localStorage[key]);
      }
    }
  } catch {
    //
  }

  return null;
}
