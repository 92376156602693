export default {
  namespaced: true,
  state: {
    speciesTreated: [],
  },
  getters: {
    getSpeciesTreated(state) {
      return state.speciesTreated;
    },
  },
  mutations: {
    setSpeciesTreated(state, speciesTreated) {
      // eslint-disable-next-line no-param-reassign
      state.speciesTreated = speciesTreated;
    },
  },
  actions: {
    async fetchSpeciesTreated({ commit }) {
      await this.$app.$api.get('p/species-treated')
        .then((response) => commit('setSpeciesTreated', response.data.data));
    },
  },
};
