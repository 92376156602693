export default {
  base:
    'cursor-pointer focus:ring-indigo focus:outline-none inline-flex justify-center items-center font-medium rounded-md transition border background-origin-border-box',
  solid:
    'text-white bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 focus:border-indigo-700',
  primary:
    'text-white bg-gradient-to-r from-purple-600 to-indigo-700 hover:from-purple-500 hover:to-indigo-500 active:bg-indigo-700 focus:border-indigo-700',
  ocean:
    'text-white bg-ocean-950 hover:bg-ocean-700 active:bg-ocean-700 focus:border-ocean-700',
  vetlive:
    'text-white bg-vetliveBlue-700 hover:bg-blue-500 active:bg-vetliveBlue-700 focus:border-vetliveBlue-700',
  outline:
    'text-indigo-700 bg-white border-indigo-700 hover:bg-indigo-100 hover:text-indigo-700',
  outlineGray:
    'text-neutral-700 bg-white border-neutral-300 hover:text-neutral-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-neutral-50 active:text-neutral-800',
  outlinePink:
    'text-pink-700 bg-white border-pink-500 hover:text-bubblegum focus:outline-none focus:border-pink-300 active:bg-pink-50 active:text-pink-800',
  secondary:
    'text-white bg-gradient-to-r from-purple-600 to-indigo-500 hover:from-purple-800 hover:to-indigo-500 active:from-purple-900 active:to-indigo-700',
  transparentOutline:
    'text-white bg-transparent border-indigo-600 hover:border-indigo-100',
  outlineWhite:
    'text-white bg-transparent border-white',
  disabled: 'opacity-50 cursor-not-allowed',
  // Temporarily changing this variant, removing bg gradient to fix ADA contrast ratio issue
  // bright:
  //   'text-white bg-gradient-to-r from-pink-500 to-pink-700 hover:from-pink-700 hover:to-pink-900 active:from-pink-800 active:to-pink-900',
  bright:
    'text-white bg-pink-700 hover:bg-pink-900 active:bg-pink-900',
  link: 'text-indigo-500 hover:text-indigo-800',
  outlineTransparent: 'text-indigo-700 bg-transparent border-indigo-700 hover:bg-indigo-200',
  noPadding: 'px-0 py-0',
  amazon: 'bg-[#0065A2] text-white hover:bg-opacity-80',
  callADoctorPlus: 'bg-[#1A416F] text-white',
  greenhouse: 'bg-[#26A580] text-white hover:bg-opacity-80',
  k9ActivityClub: 'bg-[#EC5A26] text-white hover:bg-opacity-80',
  k9CrazyDogClub: 'bg-[#8F1D58] text-white hover:bg-opacity-80',
  kagome: 'bg-[#EEBE36] text-black hover:bg-opacity-80',
  noDesignGuidelinesBlue: 'bg-ocean-950 text-white',
  ossur: 'bg-[#3274B8] text-white hover:bg-opacity-80',
  petscreening: '',
  pupJoy: 'bg-[#EA870A] text-white',
  tenantPetCare: 'bg-[#62A8AC] text-neutral-900',
  wishbone: 'bg-[#DF6137] text-white hover:bg-opacity-80',
};
