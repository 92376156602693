import Designation from '@/enums/Designation';

export default class SearchInput {
  constructor() {
    this.date_from = undefined;
    this.date_to = undefined;
    this.temp_date_from = undefined;
    this.time_from = 0;
    this.time_to = 2359;
    this.designation = Designation.Veterinarian;
    this.timezone = undefined; // TODO
    this.country = '';
    this.province = '';
    this.city = undefined;
    this.language = 'en';
    this.platform = 'WEB';
    this.currency = undefined; // TODO = Remove?
    this.full_name = undefined;
    this.species_treated = [];
    this.areas_of_interest = [];
    this.pet_uuid = undefined;
    this.concerns = [];
    this.sort_by = '';
    this.sort_order = '';
  }
}
