export default {
  namespaced: true,

  state: {
    isOnline: false,
  },

  mutations: {
    setIsOnline(state, isOnline) {
      state.isOnline = isOnline;
    },
  },

  getters: {
    getIsOnline: (state) => state.isOnline,
  },

  actions: {
    async fetchAvailability({ commit }) {
      return this.$app.$api.get('p/chat/availability')
        .then((response) => {
          commit('setIsOnline', response.data.data.is_available);
        })
        .catch(() => {});
    },
  },
};
