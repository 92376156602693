import find from 'lodash/find';
import has from 'lodash/has';
import size from 'lodash/size';
import { DateTime } from 'luxon';

import useAppointmentDatetimeFormat from '@/composables/use-appointment-datetime-format';
import useBrowserApiHelpers from '@/composables/use-browser-api-helpers';
import useSmartTime from '@/composables/use-smart-time';
import parsedLocalStorage from '@/helpers/parsedLocalStorage';
import { i18n } from '@/i18n';

export default {
  namespaced: true,

  state: {
    error: false,
    inputs: {
      date: '',
      timeslot: {},
      timezone: '',
    },
    loading: false,
    timezone: '', // From user defined or machine
    dates: {
      date: '',
    },
    availability: [],
    pageReady: false,
    partner: {
      uuid: '',
    },
    shouldShowTimezones: false,
    quote: {},
    appointmentInquiry: {},
    isAppointmentInquiryAvailable: false,
    isPartnerLoading: false,
    vcprLocation: {
      province: null,
      country: null,
    },
    availabilityFilters: {
      range: null, // Range of time from start date - Example: { days: 7 }
      limit: null, // Number of timeslots - Example: 4
    },
  },

  mutations: {
    setDate(state, date) {
      state.dates.date = date;
    },

    setInputsDate(state, date) {
      state.inputs.date = date;
    },

    setInputsTimezone(state, timezone) {
      state.inputs.timezone = timezone;
    },

    setInputsTimeslot(state, timeslot) {
      state.inputs.timeslot = timeslot;
    },

    setQuote(state, quote) {
      state.quote = quote;
    },

    setAvailability(state, availability) {
      state.availability = availability;
    },

    setAppointmentInquiry(state, appointmentInquiry) {
      state.appointmentInquiry = appointmentInquiry;
    },

    setIsAppointmentInquiryAvailable(state, isAppointmentInquiryAvailable) {
      state.isAppointmentInquiryAvailable = isAppointmentInquiryAvailable;
    },

    setIsPartnerLoading(state, isPartnerLoading) {
      state.isPartnerLoading = isPartnerLoading;
    },

    setError(state, error) {
      state.error = error;
    },

    setTimezone(state, timezone) {
      state.timezone = timezone;
    },

    setLoading(state, value) {
      state.loading = value;
    },

    setPartner(state, data) {
      state.partner = data;
    },

    setPageReady(state, value) {
      state.pageReady = value;
    },

    setShouldShowTimezones(state, value) {
      state.shouldShowTimezones = value;
    },

    setVcprLocation(state, vcprLocation) {
      if (!vcprLocation.province) {
        // TODO PURR-684 May need to UI prompt for location when within a market site
        // but the user can't be geolocated to a province. The taxes, adjusted
        // booking fees, etc. in a quote may be inaccurate.
        let province;

        switch (vcprLocation.country) {
          case 'US':
            province = 'CA';
            break;
          case 'CA':
            province = 'ON';
            break;
          case 'GB':
            province = 'ENG';
            break;
          default:
            province = null;
            break;
        }

        vcprLocation.province = province;
      }

      state.vcprLocation = vcprLocation;
    },
    setAvailabilityFilters(state, availabilityFilters) {
      state.availabilityFilters.range = availabilityFilters?.range || null;
      state.availabilityFilters.limit = availabilityFilters?.limit || null;
    },
  },
  getters: {
    canRequestToBook(state, getters, rootState, rootGetters) {
      return !rootGetters.isPartner && (
        getters.isAppointmentInquiryAvailable || getters.hasPendingAppointmentInquiry
      );
    },

    hasError(state) {
      return state.error && size(state.error);
    },

    hasSelectedTimeslot(state) {
      return !!(state.inputs.timeslot && state.inputs.timeslot.start_at);
    },

    hasQuote(state) {
      return state.quote && state.quote.uuid;
    },

    isQuoteLoading(state) {
      return state.loading;
    },

    hasAvailability(state) {
      return size(state.availability);
    },

    hasNoAvailability(state, getters) {
      return !getters.hasAvailability;
    },

    hasAppointmentInquiry(state) {
      return !!(state.appointmentInquiry?.uuid);
    },

    hasPendingAppointmentInquiry(state, getters) {
      return getters.hasAppointmentInquiry
        && size(state.appointmentInquiry?.quotes)
        && state.appointmentInquiry.status === 'WAITING_CLIENT_CONFIRM';
    },

    getError(state) {
      return state.error;
    },

    getTimezone(state) {
      return state.timezone;
    },

    getAvailability(state) {
      return state.availability;
    },

    getAppointmentInquiry(state) {
      return state.appointmentInquiry;
    },

    getQuote(state) {
      return state.quote;
    },

    getQuoteAppointmentFee(state) {
      return find(state.quote?.items, ['type', 'APPOINTMENT_FEE']);
    },

    getInputs(state) {
      return state.inputs;
    },

    getDate(state) {
      return state.inputs.date;
    },

    getPartner(state) {
      return state.partner;
    },

    getStartAtInput(state) {
      return DateTime.fromISO(state.inputs.date, {
        zone: state.inputs.timezone,
      })
        .startOf('day')
        .toUTC()
        .set({ milliseconds: 0 });
    },

    getEndAtInput(state, getters) {
      return getters.getStartAtInput
        .plus(getters.getAvailabilityFilters?.range || { days: 1 })
        .toUTC()
        .set({ milliseconds: 0 });
    },

    getAvailabilityPayload(state, getters, rootState, rootGetters) {
      const payload = {
        start_at: getters.getStartAtInput.toISO({ suppressMilliseconds: true }),
        end_at: getters.getEndAtInput.toISO({ suppressMilliseconds: true }),
        search_result_uuid: rootGetters['search/getInputs'].search_result_uuid,
      };

      if (getters.getAvailabilityFilters?.limit) {
        payload.limit = getters.getAvailabilityFilters.limit;
      }

      return payload;
    },

    canGetQuote(state) {
      return (
        state.inputs.date && state.inputs.timezone && state.inputs.timeslot
      );
    },

    canGetAvailability(state) {
      return state.inputs.date && state.inputs.timezone;
    },

    getSelectedDate(state) {
      if (!state.inputs.date) {
        return i18n.t('quote.your-selected-day');
      }

      return DateTime.fromISO(state.inputs.date)
        .setLocale(i18n.locale)
        .toLocaleString(DateTime.DATE_HUGE);
    },

    getQuotePayload(state, getters, rootState, rootGetters) {
      const clientUuid = rootGetters.getUser.uuid;
      const inputs = rootGetters['search/getInputs'];

      return {
        client_uuid: clientUuid,
        pet_uuid: clientUuid && inputs.pet_uuid,
        start_at: getters.getInputs.timeslot?.start_at,
        end_at: getters.getInputs.timeslot?.end_at,
        target_currency: rootGetters.getSiteCurrency || 'USD',
        target_city: getters.getVcprLocation.city,
        target_country: getters.getVcprLocation.country,
        target_province: getters.getVcprLocation.province,
        platform: 'WEB',
        search_result_uuid: inputs.search_result_uuid,
      };
    },

    getVcprLocation(state) {
      return state.vcprLocation;
    },

    isPartnerLoading(state) {
      return state.isPartnerLoading;
    },

    isSelectedTimeslot: (state, getters) => (timeslot) => {
      const { getTimeAsTwentyFourHours } = useAppointmentDatetimeFormat();

      return getters.hasSelectedTimeslot
        && getTimeAsTwentyFourHours(getters.getInputs.timeslot.start_at) === getTimeAsTwentyFourHours(timeslot);
    },

    isAppointmentInquiryAvailable(state) {
      return state.isAppointmentInquiryAvailable;
    },

    getAvailabilityFilters(state) {
      return state.availabilityFilters;
    },
  },

  actions: {
    async fetchQuote({ getters, commit }) {
      if (!getters.hasSelectedTimeslot) {
        commit(
          'setError',
          i18n.t('quote.select-a-timeslot-before-trying-to-get-a-quote'),
        );
        return false;
      }
      commit('setError', null);
      commit('setLoading', true);

      try {
        await this.$app.$api
          .post(`p/quote/${getters.getPartner.uuid}`, getters.getQuotePayload)
          .then(
            (response) => {
              commit('setQuote', response.data.data);
            },
            // eslint-disable-next-line handle-callback-err
            (error) => {
              // TODO: Implement ParseErrors.js here?
              commit('setError', Object.values(error.response.data.data)[0][0]);
              commit('setInputsTimeslot', '');
            },
          );
      } catch (e) {
        commit(
          'setError',
          i18n.t('quote.unable-to-get-a-quote-please-try-again-or-contact-support'),
        );
      } finally {
        commit('setLoading', false);
      }

      return true;
    },

    async fetchAvailabilityAndUseExistingTimeslot({ getters, dispatch }) {
      let oldTimeslot = getters.getInputs.timeslot;

      await dispatch('fetchAvailability', getters.getPartner);
      if (oldTimeslot && !has(getters.getAvailability, oldTimeslot.start_time)) {
        oldTimeslot = find(getters.getAvailability, {
          start_time: oldTimeslot.start_time,
        }) || getters.getAvailability[0];
        await dispatch('updateTimeslotAndFetchQuote', oldTimeslot);
      } else if (getters.hasAvailability) {
        await dispatch('updateTimeslotAndFetchQuote', getters.getAvailability[0]);
      }
    },

    async fetchAvailability({ commit, getters }) {
      commit('setLoading', true);
      commit('setError', null);

      try {
        await this.$app.$api
          .post(
            `p/partner/${getters.getPartner.uuid}/availability`,
            getters.getAvailabilityPayload,
          )
          .then(
            (response) => {
              commit('setAvailability', response.data.data);
            },
            // eslint-disable-next-line handle-callback-err
            () => {},
          );
      } catch (e) {
        commit('setError', i18n.t('quote.unable-to-get-availability'));
      } finally {
        commit('setLoading', false);
      }
    },

    async fetchVcprLocation({ commit, dispatch, rootGetters }) {
      const searchParams = parsedLocalStorage('searchParams');
      let location;

      if (searchParams?.country) {
        location = {
          city: searchParams.city,
          country: searchParams.country.toUpperCase(),
          province: searchParams.province?.toUpperCase(),
        };
      } else {
        await dispatch('geolocation/fetchGeolocation', {}, { root: true });

        const geolocation = rootGetters['geolocation/getGeolocation'];

        if (geolocation.country) {
          location = {
            city: geolocation.city,
            country: geolocation.country,
            province: geolocation.province,
          };
        } else {
          location = {
            country: rootGetters.getSiteCountryCode,
            province: null,
          };
        }
      }

      commit('setVcprLocation', location);
    },

    async updateTimeslotAndFetchQuote({ commit, getters, dispatch }, timeslot) {
      if (timeslot && timeslot !== getters.getInputs.timeslot) {
        commit('setInputsTimeslot', timeslot);

        await dispatch('fetchQuote');
      }
    },

    async setDateAndFetchAvailability({
      commit, dispatch, getters, state,
    }, date) {
      if (!date) {
        return;
      }

      commit('setDate', date);
      commit('setInputsDate', date.toISODate());

      if (state.pageReady) {
        await dispatch('fetchAvailabilityAndUseExistingTimeslot');
      }

      try {
        const { localStorageAvailable } = useBrowserApiHelpers();
        if (localStorageAvailable()) {
          localStorage.quoteParams = JSON.stringify(getters.getInputs);
        }
      } catch {
        //
      }
    },

    async updateTimezoneAndFetchAvailability({ commit, dispatch }) {
      commit('setShouldShowTimezones', false);
      commit('setInputsTimeslot', '');

      await dispatch('fetchAvailability');
    },

    async setDateOnQuote({ commit, getters }) {
      const { validDateFrom } = useSmartTime();

      await commit('setDate', validDateFrom(getters.getInputs.date));

      await commit('setInputsDate', validDateFrom(getters.getInputs.date));
    },

    async fetchAppointmentInquiry({ commit, getters, rootGetters }) {
      if (!rootGetters.isClient) {
        return;
      }

      commit('setLoading', true);
      commit('setError', null);

      try {
        const response = await this.$app.$api.get(`v1/cx/appointments/inquiry/${getters.getPartner.uuid}`);
        commit('setAppointmentInquiry', response.data.data[0]);
      } catch (e) {
        //
      } finally {
        commit('setLoading', false);
      }
    },

    async fetchAppointmentInquiryAvailability({ commit, getters, rootGetters }) {
      if (rootGetters.isPartner) {
        return;
      }

      commit('setLoading', true);
      commit('setError', null);

      try {
        await this.$app.$api.post(`p/appointments/inquiry/${getters.getPartner.uuid}`, {
          start_on: getters.getStartAtInput,
        });
        commit('setIsAppointmentInquiryAvailable', true);
      } catch (e) {
        commit('setIsAppointmentInquiryAvailable', false);
      } finally {
        commit('setLoading', false);
      }
    },
  },
};
