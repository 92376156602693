import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapGetters('globalSearch', [
      'isGlobalSearchFocussed',
      'isLoadingResult',
      'getHighlightedIndex',
      'getLoadingResultIndex',
      'getFacets',
    ]),
  },
  methods: {
    ...mapMutations('globalSearch', [
      'nextIndex',
      'prevIndex',
      'resetIndex',
      'resetFacets',
      'setIndex',
      'setLoadingResult',
    ]),
    ...mapActions('globalSearch', [
      'blurGlobalSearch',
      'focusGlobalSearch',
      'toggleFacet',
    ]),
  },
};
