/* eslint-disable no-param-reassign */
import Deferred from '@/models/Deferred';

export default {
  namespaced: true,
  state: {
    authModalMode: null,
    header: null,
    headerDescription: null,
    hideRegistration: false,
    isBooking: false,
    resetPasswordStep: null,
    willAuth: null,
  },
  getters: {
    authModalMode(state) {
      return state.authModalMode;
    },
    getHeader(state) {
      return state.header;
    },
    getHeaderDescription(state) {
      return state.headerDescription;
    },
    isBooking(state) {
      return state.isBooking;
    },
    isRegistrationHidden(state) {
      return state.hideRegistration;
    },
    resetPasswordStep(state) {
      return state.resetPasswordStep;
    },
    willAuthPromise(state) {
      return state.willAuth && state.willAuth.promise;
    },
  },
  mutations: {
    setAuthModalMode(state, value) {
      state.authModalMode = value;
    },
    setIsBooking(state, value) {
      state.isBooking = value;
    },
    setWillAuth(state, value) {
      state.willAuth = value;
    },
    awaitWillAuth(state, value) {
      if (state.willAuth) {
        state.willAuth.resolve(value);
      }
    },
    setHeader(state, value) {
      state.header = value;
    },
    setHeaderDescription(state, value) {
      state.headerDescription = value;
    },
    setResetPasswordStep(state, value) {
      state.resetPasswordStep = value;
    },
    setHideRegistration(state, value) {
      state.hideRegistration = value;
    },
  },
  actions: {
    setAuthModalMode({ commit }, payload = 'login') {
      commit('setAuthModalMode', payload);
      if (payload) {
        commit('setWillAuth', new Deferred());
      }
    },
    setIsBooking({ commit }, payload) {
      commit('setIsBooking', payload);
    },
    closeAuthModal({ commit }, success) {
      commit('setAuthModalMode', null);
      commit('setIsBooking', false);
      commit('awaitWillAuth', success);
      commit('setHeader', null);
      commit('setHeaderDescription', null);
      commit('emailVerification/setVerificationData', null, { root: true });
    },
    resetHeader({ commit }) {
      commit('setHeader', null);
      commit('setHeaderDescription', null);
    },
  },
};
