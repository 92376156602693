import AuthService from '@vetster/vetster-sdk';
import axios from 'axios';
import md5 from 'crypto-js/md5';
import { DateTime } from 'luxon';

import Platform from '@/enums/Platform';
import setGlobalProperty from '@/helpers/setGlobalProperty';
import { i18n } from '@/i18n';
import { getCloudflareAccessHeaders } from '@/packages/Cloudflare';
import { getSentryHeaders } from '@/packages/Sentry';

const createService = ({ uat }) => new AuthService({
  axios,
  md5,
  baseUrl: import.meta.env.VITE_AUTH_URL,
  cookieStorage: {
    secure: import.meta.env.VITE_COOKIE_SECURE === 'true',
    domain: import.meta.env.VITE_COOKIE_DOMAIN,
  },
  environment: import.meta.env.VITE_APP_ENVIRONMENT,
  interceptor: (config) => {
    const { locale } = i18n;

    config.headers = {
      ...config.headers,
      'Accept-Language': locale,
      'X-Vetster-Locale': locale,
      'X-Vetster-Application': 'WEB',
      'X-Vetster-Browser-Timezone': DateTime.local().zoneName,
      'X-Vetster-Platform': Platform.Web,
      ...getCloudflareAccessHeaders(),
      ...getSentryHeaders(),
    };

    if (uat) {
      config.headers['X-Vetster-UATID'] = uat;
    }

    return config;
  },
});

export default {
  install(Vue, { uat }) {
    setGlobalProperty(Vue, '$auth', createService({ uat }));
  },
};
