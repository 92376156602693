import isArray from 'lodash/isArray';
import isArrayLike from 'lodash/isArrayLike';
import isNull from 'lodash/isNull';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import some from 'lodash/some';

export default {
  methods: {
    /**
     * @param {Event} event
     * @param {Element} el Clicks outside this wrapping element trigger the callback.
     * @param {Function} callback
     * @param {Element|NodeList?} ignoredElements
     */
    hideOnClickAway(event, el, callback, ignoredElements) {
      if (
        some(
          this.wrapArray(ignoredElements),
          (ignored) => ignored && ignored.contains(event.target),
        )
      ) {
        return;
      }

      if (el
        && el !== event.target
        && window.getComputedStyle(el)?.display !== 'none'
        && !el.contains(event.target)
      ) {
        callback();
      }
    },
    wrapArray(value) {
      if (isArray(value)) {
        return value;
      }

      if (isArrayLike(value) && !isString(value)) {
        return Array.from(value);
      }

      if (!isUndefined(value) && !isNull(value)) {
        return [value];
      }

      return [];
    },
  },

  destroyed() {
    document.removeEventListener('click', () => this.hideOnClickAway);
  },
};
