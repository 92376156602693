export default {
  namespaced: true,
  state: {
    focus: false,
    highlightedIndex: -1,
    loadingResultIndex: -1,
    facets: {},
  },
  getters: {
    isGlobalSearchFocussed(state) {
      return !!state.focus;
    },
    isLoadingResult(state) {
      return state.loadingResultIndex >= 0;
    },
    getHighlightedIndex(state) {
      return state.highlightedIndex;
    },
    getLoadingResultIndex(state) {
      return state.loadingResultIndex;
    },
    getFacets(state) {
      return state.facets || {};
    },
  },

  actions: {
    focusGlobalSearch({ commit }) {
      commit('setGlobalSearchFocussed', true);
    },
    blurGlobalSearch({ commit }) {
      commit('setGlobalSearchFocussed', false);
    },
    toggleFacet({ state, commit }, facet, allowMultiple = false) {
      const currentFacets = state.facets || {};
      const newFacets = allowMultiple ? { ...currentFacets } : {};

      if (currentFacets[facet]) {
        delete newFacets[facet];
      } else {
        newFacets[facet] = true;
      }

      commit('setFacets', newFacets);
    },
  },

  mutations: {
    setGlobalSearchFocussed(state, value) {
      state.focus = value;
    },
    setFacets(state, value) {
      state.facets = value;
    },
    setIndex(state, value) {
      state.highlightedIndex = value;
    },
    nextIndex(state, resultLength) {
      if (state.highlightedIndex === resultLength - 1) {
        state.highlightedIndex = 0;
      } else {
        state.highlightedIndex = Math.min(state.highlightedIndex + 1, Math.max(resultLength - 1, 0));
      }
    },
    prevIndex(state, resultLength) {
      if (state.highlightedIndex === 0) {
        state.highlightedIndex = Math.max(resultLength - 1, 0);
      } else {
        state.highlightedIndex = Math.max(state.highlightedIndex - 1, 0);
      }
    },
    resetIndex(state) {
      state.highlightedIndex = -1;
      state.loadingResultIndex = -1;
    },
    setLoadingResult(state, value) {
      state.loadingResultIndex = value;
    },
    resetFacets(state) {
      state.facets = {};
    },
  },
};
