import Vue from 'vue';
import VueI18n from 'vue-i18n';

import fallbackMessages from '@/locales/en-US.json';

Vue.use(VueI18n);

const fallbackLocale = 'en-US';
const localesLoaded = [fallbackLocale];

const supportedLanguages = {
  en: 'en-US',
  fr: 'fr-CA',
};

const supportedLocales = [
  {
    code: 'en-CA',
    name: 'Canada (English)',
    nameDescriptive: 'Canada',
    dir: 'ltr',
    country: 'ca',
  },
  {
    code: 'fr-CA',
    name: 'Canada (Français)',
    nameDescriptive: 'Canada',
    dir: 'ltr',
    country: 'ca',
  },
  {
    code: 'en-US',
    name: 'United States',
    nameDescriptive: 'The United States',
    dir: 'ltr',
    country: 'us',
  },
  {
    code: 'en-GB',
    name: 'United Kingdom',
    nameDescriptive: 'United Kingdom',
    dir: 'ltr',
    country: 'gb',
  },
];

const languageSupported = (value) => Object.keys(supportedLanguages).includes(value?.toLowerCase());

export function getLocaleSlug(countryCode) {
  return (
    supportedLocales.find(({ country }) => country === countryCode)
    || supportedLocales.find(({ code }) => code === fallbackLocale)
  ).code.toLowerCase();
}

export function isMarketSiteCountry(countryCode) {
  return supportedLocales.some(({ country }) => country === countryCode);
}

export function localeSupported(locale) {
  return supportedLocales.some(({ code }) => code === locale);
}

export function supportedFallbackLocale(locale = null) {
  return localeSupported(locale)
    ? locale
    : fallbackLocale;
}

export function getAppLocale() {
  const locale = window.__VETSTER?.locale;

  if (localeSupported(locale) || languageSupported(locale)) {
    return locale;
  }

  return fallbackLocale;
}

const buildTranslations = () => {
  let appLocale = getAppLocale();

  if (languageSupported(appLocale)) {
    appLocale = supportedLanguages[appLocale.toLowerCase()];
  }

  const messages = {
    [fallbackLocale]: fallbackMessages,
  };

  if (appLocale !== fallbackLocale) {
    import(`@/locales/${appLocale}.json`).then((secondaryMessages) => {
      messages[appLocale] = secondaryMessages.default;
      localesLoaded.push(appLocale);
    });
  }

  return messages;
};

export const i18n = new VueI18n({
  silentTranslationWarn: true,
  locale: getAppLocale(),
  fallbackLocale,
  messages: buildTranslations(),
  numberFormats: {
    'en-CA': {
      currency: {
        style: 'currency',
        currency: 'CAD',
      },
    },
    'fr-CA': {
      currency: {
        style: 'currency',
        currency: 'CAD',
      },
    },
    'en-US': {
      currency: {
        style: 'currency',
        currency: 'USD',
      },
    },
    'en-GB': {
      currency: {
        style: 'currency',
        currency: 'GBP',
      },
    },
  },
  dateTimeFormats: {
    'en-US': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
      },
      order: 'MDY',
    },
    'en-GB': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
      },
      order: 'MDY',
    },
    'en-CA': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
      },
      order: 'YMD',
    },
    'fr-CA': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
      },
      order: 'DMY',
    },
  },
});

export function getLanguageSlug() {
  return i18n.locale.split('-')[0];
}

export function isLocaleCountry(countryCode) {
  return supportedLocales.find(({ code }) => code === i18n.locale)?.country === countryCode.toLowerCase();
}

export function setLocale(locale) {
  if (i18n.locale === locale || localesLoaded.includes(locale)) {
    return Promise.resolve(() => {
      i18n.locale = locale;

      return locale;
    });
  }

  return import(`@/locales/${locale}.json`).then((messages) => {
    i18n.locale = locale;
    i18n.setLocaleMessage(locale, messages.default);
    localesLoaded.push(locale);

    return locale;
  });
}
