/* eslint-disable no-param-reassign */
export default {
  namespaced: true,
  state: {
    wallets: [],
  },

  getters: {
    getWallets(state) {
      return state?.wallets;
    },
    getCredits(state) {
      return state?.wallets.find((wallet) => wallet.type === 'CREDIT');
    },
    getVouchers(state) {
      return state?.wallets.find((wallet) => wallet.type === 'APPOINTMENT');
    },
    hasCreditWallet(state) {
      return state.wallets.some((wallet) => wallet.type === 'CREDIT');
    },
    hasVoucherWallet(state) {
      return state.wallets.some((wallet) => wallet.type === 'APPOINTMENT');
    },
  },

  actions: {
    async fetchWallets({ commit }) {
      try {
        const response = await this.$app.$api.get('v1/wallets');
        commit('setWallets', response.data.data);
      } catch {
        //
      }
    },
    async setupWallets({ dispatch, commit, getters }) {
      if (sessionStorage.getItem('wallets')) {
        commit('setWallets', JSON.parse(sessionStorage.getItem('wallets')));
      } else {
        await dispatch('fetchWallets');
        sessionStorage.setItem('wallets', JSON.stringify(getters.getWallets));
      }
    },
  },

  mutations: {
    setWallets(state, data) {
      state.wallets = data;
    },
  },
};
