import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions('hubspot', [
      'hideHubSpotIcon',
      'showHubSpotIcon',
    ]),
  },
};
