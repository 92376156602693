import merge from 'lodash/merge';
import pick from 'lodash/pick';

class Address {
  constructor(data) {
    this.address_1 = '';
    this.address_2 = '';
    this.uuid = '';
    this.city = '';
    this.postal = '';
    this.province = '';
    this.country = '';
    this.latitude = '';
    this.longitude = '';

    if (data) {
      merge(this, pick(data, Object.keys(this)));
    }
  }

  public() {
    return `${
      this.city
    }, ${this.province.toUpperCase()}, ${this.country.toUpperCase()}`.trim();
  }
}

export default Address;
