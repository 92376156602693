import { setCookie } from '@/helpers/cookies';
import { supportedFallbackLocale } from '@/i18n';

export default {
  methods: {
    selectLocaleOption(locale, el) {
      const isGlobal = el?.target?.closest('a')?.getAttribute('data-is-global') === 'true';

      this.setLocaleCookies(supportedFallbackLocale(locale), isGlobal);
    },

    setLocaleCookies(locale, isGlobal) {
      setCookie('v-global', isGlobal, '1d');
      setCookie('v-locale', locale, '365d');
    },
  },
};
