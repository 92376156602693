import size from 'lodash/size';

import Pet from '@/models/Pet';

export default {
  namespaced: true,
  state: {
    pets: [],
  },
  getters: {
    getPets(state) {
      return Object.values(state.pets).map((pet) => new Pet(pet));
    },

    hasPets(state) {
      return size(state.pets);
    },
  },

  actions: {
    async fetchPets({ commit }) {
      await this.$app.$api
        .get('v1/cx/pets')
        .then((response) => commit('setPets', response.data.data));
    },

    async addPet(context, payload) {
      return this.$app.$api.post('v1/cx/pets', payload);
    },
  },

  mutations: {
    setPets(state, data) {
      state.pets = data;
    },

    setPetPhoto(state, data) {
      state.pet.photo = data;
    },
  },
};
