/* eslint-disable import/prefer-default-export */
function analyticsDefaultPayload() {
  return {
    referrer_url: document.referrer,
    url: window.location.href,
  };
}

export {
  analyticsDefaultPayload,
};
