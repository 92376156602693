import isEmpty from 'lodash/isEmpty';
import { DateTime } from 'luxon';

export default function processInputsData(data) {
  if (data.date_from && DateTime.isDateTime(data.date_from)) {
    data.date_from = data.date_from.toISODate();
  }
  if (data.date_to && DateTime.isDateTime(data.date_to)) {
    data.date_to = data.date_to.toISODate();
  }
  if (!isEmpty(data.time_from)) {
    data.time_from = data.time_from.toString().includes(':')
      ? parseInt(data.time_from.replace(':', ''), 10)
      : parseInt(data.time_from, 10);
  }
  if (!isEmpty(data.time_to)) {
    data.time_to = data.time_to.toString().includes(':')
      ? parseInt(data.time_to.replace(':', ''), 10)
      : parseInt(data.time_to, 10);
  }
  return data;
}
