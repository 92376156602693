import Vue from 'vue';

import { analyticsDefaultPayload } from '@/helpers/analytics';
import { getCookie } from '@/helpers/cookies';

// TEMPORARY: This gate should be removed during events refactoring phase 2
const skipsFullStoryEvents = () => Vue.prototype.$statsig?.ready
  && Vue.prototype.$statsig.checkGate('block_fullstory_tracking');

export function fullStoryIdentify(user = null) {
  const uatId = getCookie('v-uatid');

  try {
    window.FS.setUserVars(user ? {
      type: user.isClient() ? 'client' : 'partner',
      uatId,
    } : {
      uatId,
    });
  } catch {
    // FullStory may be blocked by browser extensions.
  }
}

export function fullStoryTrack(eventName, payload) {
  try {
    if (!skipsFullStoryEvents()) {
      window.FS.event(eventName, {
        ...analyticsDefaultPayload(),
        ...payload,
      });
    }
  } catch {
    // FullStory may be blocked by browser extensions.
  }
}

export function fullStoryReadyCallback(sentry) {
  // eslint-disable-next-line no-underscore-dangle
  window._fs_ready = () => {
    sentry.setContext('FullStory', {
      sessionURL: window.FS.getCurrentSessionURL(),
    });
  };
}
