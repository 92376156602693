import merge from 'lodash/merge';

class Pet {
  constructor(data) {
    this.uuid = '';
    this.species = '';
    this.spayed_neutered = null;
    this.gender = '';
    this.name = '';
    this.color = '';
    this.color_2 = '';
    this.birthday_on = '';
    this.weight = {
      value: '',
      is_metric: 0,
    };
    this.has_photo = null;

    merge(this, data);
  }
}

export default Pet;
