export default {
  namespaced: true,

  state: {
    identification: {
      email: null,
      token: null,
    },
    isReady: false,
  },

  mutations: {
    setIdentification(state, payload) {
      state.identification = {
        email: payload?.email,
        token: payload?.token,
      };
    },
    setReady(state) {
      state.isReady = true;
    },
  },

  getters: {
    hasHubSpotIdentification(state) {
      return !!state.identification.token;
    },
    isHubSpotReady(state) {
      return state.isReady;
    },
  },

  actions: {
    identify({ commit, dispatch, state }, payload) {
      if (window.hsConversationsSettings) {
        commit('setIdentification', payload);

        window.hsConversationsSettings = {
          identificationEmail: state.identification.email,
          identificationToken: state.identification.token,
        };

        dispatch('load');
      }
    },
    load() {
      window.HubSpotConversations?.widget.load();
    },
    openHubSpot({ dispatch, getters }) {
      // Open chat widget as a guest missing an identification token.
      if (!getters.hasHubSpotIdentification) {
        dispatch('load');
      }

      window.HubSpotConversations?.widget.open();
    },
    hideHubSpotIcon() {
      this.$app.$nextTick(() => {
        document.getElementById('hubspot-messages-iframe-container')?.classList.add('hidden');
      });
    },
    showHubSpotIcon() {
      this.$app.$nextTick(() => {
        document.getElementById('hubspot-messages-iframe-container')?.classList.remove('hidden');
      });
    },
    setup({ commit, dispatch }) {
      window.hsConversationsOnReady = [() => {
        dispatch('load');
        commit('setReady');
      }];
    },
    async setupToken({ dispatch }) {
      return this.$app.$api.get('v1/profile/identify/hs').then(
        (response) => {
          dispatch('identify', response.data?.data);
        },
        () => {},
      );
    },
  },
};
