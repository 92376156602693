const domain = import.meta.env.VITE_COOKIE_DOMAIN;
const isSecure = import.meta.env.VITE_COOKIE_SECURE === 'true';

const forgetCookie = (key) => {
  if (window.$cookies.get(key)) {
    window.$cookies.remove(key, null, domain);
  }
};

const getCookie = (key) => window.$cookies.get(key);

const hasCookie = (key) => window.$cookies.isKey(key);

const setCookie = (key, value, expires = null) => {
  window.$cookies.set(key, value, expires, null, domain, isSecure);
};

export {
  forgetCookie,
  getCookie,
  hasCookie,
  setCookie,
};
