import axios from 'axios';
import pickBy from 'lodash/pickBy';
import { DateTime } from 'luxon';

import Platform from '@/enums/Platform';
import { getCookie } from '@/helpers/cookies';
import setGlobalProperty from '@/helpers/setGlobalProperty';
import { getCloudflareAccessHeaders } from '@/packages/Cloudflare';
import { getSentryHeaders } from '@/packages/Sentry';
import store from '@/store';

export default {
  install(Vue, { uat }) {
    const api = axios.create({
      baseURL: import.meta.env.VITE_API_URL,
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });

    api.interceptors.request.use(
      async (config) => {
        const authenticated = await Vue.prototype.$auth.authenticate();
        const { locale } = store.state.site;

        config.headers = {
          ...config.headers,
          ...pickBy({
            Authorization: authenticated ? `Bearer ${await Vue.prototype.$auth.getAccessToken()}` : null,
            'Accept-Language': authenticated ? locale : null,
            'X-Vetster-Locale': locale,
            'X-Vetster-I': getCookie('v-i'),
            'X-Vetster-UATID': uat,
          }, (value) => value),
          'X-Vetster-Browser-Timezone': DateTime.local().zoneName,
          'X-Vetster-Platform': Platform.Web,
          ...getCloudflareAccessHeaders(),
          ...getSentryHeaders(),
        };

        return config;
      },
      (error) => {
        throw error;
      },
    );

    api.interceptors.response.use(
      async (response) => response,
      (error) => {
        throw error;
      },
    );

    setGlobalProperty(Vue, '$api', api);
  },
};
