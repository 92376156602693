export default {
  namespaced: true,
  state: {
    errorBag: [],
  },
  getters: {},
  actions: {},
  mutations: {
    clear(state) {
      // eslint-disable-next-line no-param-reassign
      state.errorBag = [];
    },
    add(state, error) {
      state.errorBag.push({
        field: error.field,
        message: error.message,
      });
    },
  },
};
