export default {
  methods: {
    applyScrollPadding(value) {
      const elementsToPad = document.querySelectorAll('[data-scroll-padding]');
      elementsToPad.forEach((el) => {
        el.style.paddingRight = `${value}px`;
      });
    },
    disableScroll() {
      const { scrollY, innerWidth } = window;
      const scrollbarWidth = innerWidth - document.documentElement.clientWidth;
      this.applyScrollPadding(scrollbarWidth);

      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = '100%';
      document.body.style.overflow = 'hidden';
    },
    enableScroll() {
      const scrollY = document.body.style.top;
      this.applyScrollPadding(0);

      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      document.body.style.overflow = '';

      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    },
  },
};
