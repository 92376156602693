export default {
  namespaced: true,
  state: {
    geolocation: {
      city: null,
      province: null,
      country: null,
    },
    isFetched: false,
    request: null,
  },
  getters: {
    getGeolocation(state) {
      return state.geolocation;
    },
  },
  actions: {
    async fetchGeolocation({ commit, state }) {
      if (state.isFetched) {
        return;
      }

      if (state.request) {
        await state.request;

        return;
      }

      // Avoid the Axios request to vetster-api when Cloudflare HTTP headers
      // already contain a city location.
      const geolocation = {
        city: window.__VETSTER.geo.city,
        province: window.__VETSTER.geo.region,
        country: window.__VETSTER.geo.country,
      };

      if (geolocation.city
        && geolocation.province
        && geolocation.country
      ) {
        commit('setGeolocation', geolocation);
        commit('setIsFetched');
        commit('setRequest', null);

        return;
      }

      const request = this.$app.$api.get('p/geo')
        .then((response) => {
          const payload = response.data.data;

          commit('setGeolocation', {
            city: payload.city,
            province: payload.region_code,
            country: payload.country_code,
          });
        })
        .catch(() => {})
        .finally(() => {
          commit('setIsFetched');
          commit('setRequest', null);
        });

      commit('setRequest', request);
    },
  },
  mutations: {
    setGeolocation(state, geolocation) {
      state.geolocation = geolocation;
    },
    setIsFetched(state) {
      state.isFetched = true;
    },
    setRequest(state, value) {
      state.request = value;
    },
  },
};
