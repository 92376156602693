import { getCurrentInstance } from 'vue';

export default function useKeyboardEvents() {
  const root = getCurrentInstance().proxy.$root;

  const emitCloseOnEscape = () => {
    document.body.addEventListener('keyup', (event) => {
      if (event && event.keyCode === 27) {
        root.$bus.$emit('escape');
      }
    });
  };

  return { emitCloseOnEscape };
}
