import useMembershipPromotion from '@/composables/use-membership-promotion';
import SubscriptionPlanType from '@/enums/SubscriptionPlanType';

export default {
  namespaced: true,

  state: {
    promotion: { showOriginalPriceStrikethrough: false },
    yearlySubscriptionPlan: null,
  },

  getters: {
    hasPromotion(state) {
      return !!state.promotion?.isEnabled;
    },
    getYearlySubscriptionPlan(state, getters) {
      const plan = state.yearlySubscriptionPlan;
      const { promotion } = state;

      if (getters.hasPromotion) {
        plan.product.price = {
          currency: plan.product.price.currency,
          ...promotion.productPrice,
          originalAmount: promotion?.originalPrice?.amount ?? plan.product.price.amount,
        };
      }

      return plan;
    },
    showOriginalPriceStrikethrough(state, getters) {
      return getters.hasPromotion && state.promotion?.showOriginalPriceStrikethrough;
    },
  },

  mutations: {
    setPromotion(state, promotion) {
      state.promotion = promotion;
    },
    setYearlySubscriptionPlan(state, plan) {
      state.yearlySubscriptionPlan = plan;
    },
  },

  actions: {
    async fetchSubscriptionPlan({ commit }, filter) {
      const response = await this.$app.$api.get('/p/subscription-plans', {
        params: {
          filter,
        },
      });

      commit('setYearlySubscriptionPlan', response.data.data[0]);
    },
    async setSubscriptionPlan({ commit, dispatch, getters }) {
      const subscriptionPlanId = this.$app?.$statsig?.ready
        ? this.$app.$statsig.getConfig('subs_vetster_plus_default_plan')?.value?.uuid
        : null;

      if (subscriptionPlanId) {
        const response = await this.$app.$api.get(`/p/subscription-plans/${subscriptionPlanId}`);
        commit('setYearlySubscriptionPlan', response.data.data);
      } else {
        await dispatch('fetchSubscriptionPlan', { type: SubscriptionPlanType.Membership });
      }

      const membershipPromotion = useMembershipPromotion(this.$app, getters.getYearlySubscriptionPlan);
      if (membershipPromotion.promotion) {
        commit('setPromotion', membershipPromotion.promotion);
      }
    },
  },
};
