import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';

import SubscriptionPlanType from '@/enums/SubscriptionPlanType';
import {
  calculateAppointmentsCount,
  getMembershipCoInsuranceAmount,
} from '@/helpers/subscriptions';
import { i18n } from '@/i18n';

export default {
  namespaced: true,
  state: {
    activeSubscriptions: [],
  },
  getters: {
    canPurchaseSubscriptionPlan(state, getters, rootState, rootGetters) {
      const localesEnabled = ['en-US', 'en-CA', 'fr-CA'];
      const user = rootGetters.getUser;

      return !getters.hasActiveSubscription
        && localesEnabled.includes(i18n.locale)
        && (
          (rootGetters.isGuest && !rootGetters.isGlobalUser)
          || (user.isClient()
            && !user.isGlobal()
            && !user.isFetch()
            && !user.isPetmeds()
            && localesEnabled.includes(user.locale))
        );
    },
    getCoinsurancePercentage(state, getters) {
      if (getters.hasIndividualSubscription && !isEmpty(getters.getIndividualSubscription?.promo_codes)) {
        return getMembershipCoInsuranceAmount(getters.getIndividualSubscription.promo_codes[0]);
      }
      return undefined;
    },
    getSubscriptions(state) {
      return state.activeSubscriptions;
    },
    getIndividualSubscription(state) {
      return state.activeSubscriptions.find((subscription) => [SubscriptionPlanType.Membership, SubscriptionPlanType.Wellness, SubscriptionPlanType.PlusChat].includes(subscription.plan.type));
    },
    hasActiveSubscription(state, getters) {
      return size(getters.getSubscriptions) > 0;
    },
    hasIndividualSubscription(state, getters) {
      return size(getters.getIndividualSubscription) > 0;
    },
    remainingAppointmentsCount(state, getters) {
      return calculateAppointmentsCount(getters.getIndividualSubscription?.appointment_vouchers, 'balance');
    },
  },
  mutations: {
    setSubscriptions(state, subs) {
      state.activeSubscriptions = subs;
    },
  },
  actions: {
    async fetchSubscriptions({ commit, dispatch }) {
      try {
        const response = await this.$app.$api.get('v1/cx/subscriptions', {
          params: {
            filter: {
              active: 1,
            },
          },
        });
        const subscriptions = response.data.data;

        commit('setSubscriptions', subscriptions);

        if (!isEmpty(subscriptions)) {
          dispatch('updateStatsigUser', {}, { root: true });
        }
      } catch (e) {
        //
      }
    },
  },
};
