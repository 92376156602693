import Bowser from 'bowser';

import store from '@/store';

export default function storeDeviceData() {
  const currentDevice = Bowser.getParser(window.navigator.userAgent);
  store.commit('setDeviceOS', currentDevice.getOSName());
  store.commit('setDeviceBrowser', currentDevice.getBrowserName());
  store.commit('setPlatformType', currentDevice.getPlatformType());
}
