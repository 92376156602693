export default class SocialiteProvider {}

SocialiteProvider.apple = {
  label: 'Apple',
  value: 'apple',
};

SocialiteProvider.facebook = {
  label: 'Facebook',
  value: 'facebook',
};

SocialiteProvider.google = {
  label: 'Google',
  value: 'google',
};

SocialiteProvider.googleOneTap = {
  label: 'Google One Tap',
  value: 'google-one-tap',
};

SocialiteProvider.values = () => [
  SocialiteProvider.apple,
  SocialiteProvider.facebook,
  SocialiteProvider.google,
  SocialiteProvider.googleOneTap,
];
