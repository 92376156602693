// eslint-disable-next-line import/prefer-default-export
export function formatMoney(value) {
  return (value / 100).toFixed(2);
}

export function formatMoneyRounded(value) {
  return Math.ceil(formatMoney(value));
}

export function sentenceCase(str) {
  return (str ?? '').charAt(0).toUpperCase() + (str ?? '').slice(1).toLowerCase();
}

export function snakeCase(str) {
  return (str ?? '').toLowerCase().replace(/ +/g, '_');
}

export function slugCase(str) {
  return (str ?? '').toLowerCase().trim().replace(/&+/g, 'and').replace(/[ \-_]+/g, '-');
}

export function wrapHash(str) {
  const strHash = slugCase((str ?? '').trim().replace(/#+/, ''));
  return strHash ? `#${strHash}` : '';
}

export function titleCase(str) {
  return (str ?? '')
    .split(/[ _]/)
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase())
    .join(' ');
}

export function toFixed(value, precision = 1) {
  return parseFloat(value).toFixed(precision);
}
