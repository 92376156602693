/**
 * Vue 2 to Vue 3 bridge for registering plugin globals.
 */
export default function setGlobalProperty(Vue, key, value) {
  const globalProperties = Vue.config?.globalProperties;

  (globalProperties || Vue.prototype)[key] = value;

  if (globalProperties) {
    Vue.provide(key, value);
  }
}
