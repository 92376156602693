import { uuid } from '@/helpers/uuid';

const CommonInputAttributes = {
  data() {
    return {
      currentValue: this.value,
      valueWhenFocus: null,
    };
  },

  watch: {
    value(value) {
      this.currentValue = value;
    },

    currentValue(currentValue) {
      this.$emit('input', currentValue);
    },
  },

  props: {
    value: {
      type: [String, Number, Boolean, Array],
      default: null,
    },
    reset: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: null,
    },
    max: {
      type: [String, Number],
      default: null,
    },
    maxlength: {
      type: [String, Number],
      default: null,
    },
    min: {
      type: [String, Number],
      default: null,
    },
    minlength: {
      type: [String, Number],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: undefined,
    },
    pattern: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: null,
    },
    optional: {
      type: Boolean,
      default: false,
    },
    optionalLabel: {
      type: String,
      default: null,
    },
    isPrivate: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    feedback: {
      type: String,
      default: null,
    },
    shake: {
      type: Boolean,
      default: false,
    },
    descriptionLabel: {
      type: String,
      default: null,
    },
  },

  computed: {
    getId() {
      return this.id || uuid();
    },
  },

  methods: {
    onBlur(e) {
      this.$emit('blur', e);
      if (this.currentValue !== this.valueWhenFocus) {
        this.$emit('change', this.currentValue);
      }
    },
    onFocus(e) {
      this.$emit('focus', e);
      this.valueWhenFocus = this.currentValue;
    },
    blur() {
      this.$el.blur();
    },
    click() {
      this.$el.click();
    },
    focus() {
      this.$el.focus();
    },
    select() {
      this.$el.select();
    },
    setSelectionRange() {
      this.$el.setSelectionRange();
    },
    setRangeText() {
      this.$el.setRangeText();
    },
  },
};

export default CommonInputAttributes;
