import { mapActions, mapGetters } from 'vuex';

export default {
  mounted() {
    this.$store.dispatch('hubspot/setup');
  },

  watch: {
    async isHubSpotReady(value) {
      if (value && window.__VETSTER?.opensHubSpotOnLoad) {
        await this.openHubSpot();
      }
    },
  },

  computed: {
    ...mapGetters('hubspot', ['isHubSpotReady']),
  },

  methods: {
    ...mapActions('hubspot', ['openHubSpot']),
  },
};
