<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/pro-light-svg-icons';

import CommonAttributes from '@/mixins/CommonAttributes';

library.add(faCircleNotch);

const variants = {
  base: 'relative inter font-semibold leading-4 text-base rounded focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 transition border border-transparent',

  // UPDATED BUTTON STYLES - MIGRATE TO THESE AND USE NET NEW
  // NOTE: 'pink' is the new default button style - we should work to replace all instances of 'accentPink' with 'bubblegum'
  // All usage of `VetsterButton` should ensure the text is wrapped in a `span` tag to ensure the text is centered
  bubblegum: 'text-center shrink-0 flex flex-row space-x-2 items-center justify-center whitespace-nowrap rounded-full bg-bubblegum text-black font-semibold focus:bg-bubblegum-800 focus:outline-bubblegum-400 focus:text-white hover:bg-bubblegum-700 hover:text-white hover:shadow-md active:text-white active:bg-bubblegum-800 focus-visible:outline-bubblegum-600 disabled:bg-bubblegum disabled:border-bubblegum disabled:text-black',
  ocean: 'text-center shrink-0 flex flex-row space-x-2 items-center justify-center whitespace-nowrap rounded-full bg-ocean text-white font-semibold focus:bg-ocean-900 focus:outline-ocean-700 focus:text-white hover:bg-ocean-700 hover:text-white hover:shadow-md active:text-white active:bg-ocean-800 focus-visible:outline-ocean-600 disabled:bg-ocean disabled:border-ocean disabled:text-white',
  white: 'text-center shrink-0 flex flex-row space-x-2 items-center justify-center rounded-full !border-neutral-900 bg-white text-neutral-800 hover:bg-neutral-200 hover:shadow-md active:bg-neutral-100 active:outline active:outline-offset-2 active:outline-2 active:outline-neutral-400 disabled:bg-neutral-100 disabled:border-neutral-100 disabled:text-neutral-100',
  outline: 'text-center shrink-0 flex flex-row space-x-2 items-center justify-center rounded-full bg-transparent text-white hover:underline hover:shadow-md active:bg-ocean-100 active:outline active:outline-offset-2 active:outline-2 active:outline-neutral-400 disabled:bg-neutral-100 disabled:border-neutral-100 disabled:text-neutral-600',
  outlineOcean: 'text-center shrink-0 flex flex-row space-x-2 items-center justify-center rounded-full !border-ocean-900 bg-transparent text-ocean-900 hover:underline hover:shadow-md active:bg-ocean-100 active:outline active:outline-offset-2 active:outline-2 active:outline-neutral-400 disabled:bg-neutral-100 disabled:border-neutral-100 disabled:text-neutral-600',
  outlineBubblegum: 'text-center shrink-0 flex flex-row space-x-2 items-center justify-center whitespace-nowrap rounded-full !border-bubblegum  text-black font-semibold focus:bg-bubblegum-800 focus:outline-bubblegum-400 focus:text-white hover:bg-bubblegum-700 hover:text-white hover:shadow-md active:text-white active:bg-bubblegum-800 focus-visible:outline-bubblegum-600 disabled:bg-bubblegum disabled:border-bubblegum disabled:text-black',
  disabled: 'opacity-50 cursor-not-allowed',

  // -------

  enabled: 'cursor-pointer',

  primary: 'rounded-full bg-grimace-800 hover:bg-grimace-600 focus-visible:outline-grimace-600 active:bg-grimace-700 active:outline active:outline-offset-2 active:outline-2 active:outline-grimace-400 text-white disabled:bg-grimace-100 disabled:border-grimace-100 disabled:text-neutral-400',
  accentPink: 'rounded-full bg-bubblegum-500 text-neutral-900 font-semibold focus:bg-bubblegum-800 focus:outline-bubblegum-400 focus:text-white hover:bg-bubblegum-700 hover:text-white hover:shadow-md active:text-white active:bg-bubblegum-800 focus-visible:outline-bubblegum-600 disabled:bg-bubblegum disabled:border-bubblegum disabled:text-neutral-900',
  sunflower: 'rounded-full bg-sunflower-400 hover:bg-sunflower-300 focus-visible:outline-sunflower-400 text-neutral-900',
  secondary: '!border-ocean-900 text-ocean-900 hover:shadow-md hover:bg-grimace-50',
  secondaryPink: 'rounded-full !border-bubblegum-300 bg-white text-neutral-900 hover:bg-bubblegum-50 hover:shadow-md active:bg-bubblegum-100 active:outline active:outline-offset-2 active:outline-2 active:outline-bubblegum-400 disabled:bg-neutral-100 disabled:border-neutral-100 disabled:text-neutral-600',
  secondaryPurple: 'rounded-full !border-grimace-300 bg-white text-neutral-900 hover:bg-grimace-50 hover:shadow-md active:bg-grimace-100 active:outline active:outline-offset-2 active:outline-2 active:outline-grimace-400 disabled:bg-neutral-100 disabled:border-neutral-100 disabled:text-neutral-600',
  secondaryOcean: 'flex flex-row space-x-2 items-center truncate bg-ocean-100 text-ocean-950 whitespace-nowrap hover:bg-opacity-70 hover:text-ocean-950 hover:shadow-md active:text-white active:bg-ocean-800 focus-visible:outline-ocean-300',
  tertiary: 'text-neutral-900 disabled:text-neutral-600 bg-white hover:bg-grimace-50 disabled:bg-neutral-100 active:bg-grimace-100 active:outline-grimace-300 outline outline-1 outline-grimace-300 disabled:outline-none',
  textLink: 'text-ocean-800 hover:text-ocean-600 focus:text-ocean-700 active:text-ocean-700 disabled:text-ocean-200',

  petscreening: 'bg-[#001A5E] rounded-full text-white font-bold font-title',
};

const sizes = {
  xs: 'py-2 px-3 text-xs',
  sm: 'py-2 px-4 text-sm',
  tight: 'py-0 px-0 text-sm',
  base: 'py-3 px-6 text-base',
  fixed: 'h-11 py-2 px-6 text-base',
  lg: 'py-4 px-12 text-lg',
  xl: 'py-3 px-8',
};

export default {
  name: 'VetsterButton',

  mixins: [CommonAttributes],

  props: {
    tagName: {
      type: String,
      default: 'button',
      validator(value) {
        return ['button', 'a'].includes(value);
      },
    },
    value: {
      type: [String, Number],
      default: null,
    },
    type: {
      type: String,
      default: 'button',
    },
    href: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    to: {
      type: [String, Object],
      default: undefined,
    },
    replace: {
      type: Boolean,
      default: false,
    },
    append: {
      type: Boolean,
      default: false,
    },
    activeClass: {
      type: String,
      default: 'router-link-active',
    },
    exact: {
      type: Boolean,
      default: false,
    },
    exactActiveClass: {
      type: String,
      default: 'router-link-exact-active',
    },
    variant: {
      type: String,
      default: undefined,
    },
    size: {
      type: String,
      default: 'base',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    currentClass() {
      const classes = [variants.base];

      if (variants[this.variant]) {
        classes.push(variants[this.variant]);
      }

      if (sizes[this.size]) {
        classes.push(sizes[this.size]);
      }

      if (this.disabled) {
        classes.push(variants.disabled);
      } else {
        classes.push(variants.enabled);
      }

      return classes;
    },

    componentToRender() {
      if (this.hasRouterLink) {
        return this.routerLinkComponent;
      }

      if (this.href) {
        return 'a';
      }

      return this.tagName;
    },

    hasRouterLink() {
      return this.to !== undefined && !!this.routerLinkComponent;
    },

    routerLinkComponent() {
      return this.$options.components.RouterLink;
    },
  },

  methods: {
    onBlur(e) {
      this.$emit('blur', e);
    },

    onFocus(e) {
      this.$emit('focus', e);
    },

    onClick(e) {
      this.$emit('click', e);
    },

    blur() {
      this.$el.blur();
    },

    focus() {
      this.$el.focus();
    },

    getAttributes() {
      const baseAttributes = {
        autofocus: this.autofocus,
        disabled: this.disabled,
        id: this.id,
        name: this.name,
        type: this.type,
        value: this.value,
      };

      if (this.isLoading) {
        baseAttributes['aria-busy'] = true;
        baseAttributes.disabled = true;
        baseAttributes.tabindex = -1;
      }

      const dynamicAttributes = this.hasRouterLink ? {
        activeClass: this.activeClass,
        append: this.append,
        event: ['click', 'focus', 'blur'],
        exact: this.exact,
        exactActiveClass: this.exactActiveClass,
        replace: this.replace,
        tag: this.tagName,
        to: this.to,
      } : {
        href: this.href,
        target: this.target,
      };

      return { ...baseAttributes, ...dynamicAttributes };
    },

    getDefaultSlot(createElement) {
      if (!this.isLoading) {
        return this.$slots.default;
      }

      return createElement(
        'span',
        { class: this.isLoading ? 'opacity-0' : 'opacity-100' },
        this.$slots.default,
      );
    },

    getSpinner(createElement) {
      if (!this.isLoading) {
        return null;
      }

      const spinner = createElement(
        'fa',
        {
          props: {
            icon: ['fal', 'circle-notch'],
            spin: true,
          },
        },
      );

      return createElement(
        'span',
        { class: 'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2' },
        [spinner],
      );
    },
  },

  render(createElement) {
    return createElement(
      this.componentToRender,
      {
        attrs: this.getAttributes(),
        class: this.currentClass,
        on: {
          click: this.onClick,
          focus: this.onFocus,
          blur: this.onBlur,
        },
      },
      [
        this.getSpinner(createElement),
        this.getDefaultSlot(createElement),
      ].filter(Boolean),
    );
  },
};
</script>
