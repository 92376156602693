import Vue from 'vue';

Vue.prototype.$bus = new Vue({
  name: 'AppBus',

  data: {
    shareModalOpen: false,
    stickyNavOpen: true,
  },
});
