import { computed } from 'vue';

export default function useMembershipPromotion(root, plan) {
  // To enable a future promotion, Vuex setSubscriptionPlan() must call
  // commit('setPromotion', useMembershipPromotion().promotion);
  // to configure below.
  // const isEnabled = false; // computed(() => root.$statsig.ready && root.$statsig.checkGate('checkgate'))

  const isStrikethroughPricingEnabled = computed(() => root.$statsig.ready && root.$statsig.checkGate('booking_strikethrough_pricing'));

  const promotion = computed(() => {
    if (isStrikethroughPricingEnabled.value) {
      const price = plan.product?.price?.amount;
      const msrp = plan.product?.msrp?.amount;

      return {
        isEnabled: msrp > price,
        productPrice: { amount: price },
        originalPrice: { amount: msrp },
        showOriginalPriceStrikethrough: msrp > price,
      };
    }

    return null;
  });

  return { promotion };
}
