import slice from 'lodash/slice';

export default {
  namespaced: true,
  state: {
    reviews: [],
    currentIndex: 0,
  },
  getters: {
    getReviews(state) {
      return state.reviews;
    },
    getCurrentIndex(state) {
      return state.currentIndex;
    },
    getNextTenReviews(state, getters) {
      return slice(getters.getReviews, getters.getCurrentIndex, getters.getCurrentIndex + 10);
    },
  },
  mutations: {
    setCurrentIndex(state, index) {
      state.currentIndex = index;
    },
    setReviews(state, reviews) {
      state.reviews = reviews;
    },
  },
};
