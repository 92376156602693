<template>
  <div
    :style="{ '--border-radius': `${borderRadius}px` }"
    :class="[
      'relative grid place-items-center rounded-[--border-radius] overflow-visible',
      className,
    ]"
  >
    <div
      :style="{
        '--border-width': `${borderWidth}px`,
        '--border-radius': `${borderRadius}px`,
        '--shine-pulse-duration': `${duration}s`,
        '--mask-linear-gradient': `linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)`,
        '--background-radial-gradient': `radial-gradient(transparent, transparent, ${Array.isArray(color) ? color.join(',') : color}, transparent, transparent)`,
      }"
      class="pointer-events-none z-50 before:bg-shine-size before:absolute before:inset-0 before:aspect-square before:size-full before:rounded-[--border-radius] before:p-[--border-width] before:will-change-[background-position] before:content-[''] before:![-webkit-mask-composite:xor] before:![mask-composite:exclude] before:[background-image:--background-radial-gradient] before:[background-size:300%_300%] before:[mask:--mask-linear-gradient] motion-safe:before:animate-[shine-pulse_var(--shine-pulse-duration)_infinite_linear]"
    />
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VetsterShineBorder',

  props: {
    borderRadius: {
      type: Number,
      default: 8,
    },
    borderWidth: {
      type: Number,
      default: 1,
    },
    duration: {
      type: Number,
      default: 14,
    },
    color: {
      type: [String, Array],
      default: '#000000',
    },
    className: {
      type: String,
      default: '',
    },
  },
};
</script>
