import Vue from 'vue';
import VueTippy, { TippyComponent } from 'vue-tippy';

export default function registerVueTippy() {
  Vue.use(VueTippy, {
    directive: 'tippy',
    flipDuration: 0,
    zIndex: 9999999999,
    popperOptions: {
      modifiers: {
        preventOverflow: {
          enabled: false,
        },
        hide: {
          enabled: false,
        },
      },
    },
  });

  // eslint-disable-next-line vue/multi-word-component-names
  Vue.component('Tippy', TippyComponent);
}
