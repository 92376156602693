<template>
  <div :class="{ 'animated shake fast': shake }">
    <div class="flex justify-start">
      <label
        v-if="label"
        :class="labelClass"
        :for="id"
        class="block vetster-label sm:text-neutral-700 sm:text-sm sm:leading-5"
      >
        {{ label }}
      </label>
      <span
        v-if="isPrivate"
        class="text-sm leading-5 text-indigo-500 ml-1"
      >
        <fa
          v-tippy
          :icon="['fal', 'lock']"
          class="fa-fw text-sm"
          :content="$t('payment.data-entered-here-will-remain-private')"
        />
      </span>
      <span
        v-if="tooltip"
        class="text-sm leading-5 text-indigo-500 ml-1"
      >
        <fa
          v-tippy
          :icon="tooltipIcon"
          class="fa-fw text-sm focus:outline-none focus:ring"
          :content="tooltip"
        />
      </span>
      <span
        v-if="optional || optionalLabel"
        class="text-sm flex-1 text-right leading-6 sm:leading-5 text-neutral-500"
        :class="optionalLabelClass"
      >
        {{ optionalLabel || $t('common.optional') }}
      </span>
    </div>
    <slot />
    <p
      v-if="feedback"
      class="mt-1 text-sm"
      :class="feedbackClass"
    >
      {{ feedback }}
    </p>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faExclamationCircle,
  faLock,
  faQuestion,
} from '@fortawesome/pro-light-svg-icons';

library.add({
  faExclamationCircle,
  faLock,
  faQuestion,
});

export default {
  name: 'VetsterInputGroup',

  props: {
    id: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    optional: {
      type: Boolean,
      default: false,
    },
    optionalLabel: {
      type: String,
      default: null,
    },
    isPrivate: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: null,
    },
    tooltipIcon: {
      type: Array,
      default: () => ['fal', 'question'],
    },
    feedback: {
      type: [String, Boolean],
      default: null,
    },
    shake: {
      type: Boolean,
      default: false,
    },
    optionalLabelClass: {
      type: String,
      default: '',
    },
  },

  computed: {
    labelClass() {
      if (this.error) {
        return 'text-red-700 font-semibold sm:text-red-700';
      }
      return '';
    },
    feedbackClass() {
      if (this.error) {
        return 'text-red-600 sm:text-red-600';
      }
      return '';
    },
  },
};
</script>
