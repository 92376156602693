/* eslint-disable no-param-reassign */
export default {
  namespaced: true,

  state: {
    access_token: null,
    auth_id: null,
    channel: null,
    provider_type: null,
    show_email_verification: false,
    email: null,
    phoneNumber: null,
  },

  getters: {
    getChannel(state) {
      return state.channel;
    },
    getSocialite(state) {
      return {
        provider_type: state.provider_type,
        access_token: state.access_token,
      };
    },
    getUser(state) {
      return {
        user: state.auth_id,
      };
    },
    showChannelVerification(state) {
      return !!state.channel;
    },
    showEmailVerification(state) {
      return state.show_email_verification;
    },
    getEmail(state) {
      return state.email;
    },
    getPhoneNumber(state) {
      return state.phoneNumber;
    },
  },

  mutations: {
    setChannelVerification(state, channel) {
      state.channel = channel || null;
    },
    setSocialite(state, data) {
      state.provider_type = data?.provider_type;
      state.access_token = data?.access_token;
    },
    setUser(state, data) {
      state.auth_id = data?.auth_id;
    },
    showEmailVerification(state, showEmailVerification) {
      // Clean up the socialite verification flow - use channels instead of a fixed email.
      state.show_email_verification = showEmailVerification || false;
    },
    setVerificationData(state, data) {
      state.channel = data?.channel;
      state.email = data?.email;
      state.phoneNumber = data?.phone_number;
    },
  },

  actions: {},
};
