import { analyticsDefaultPayload } from '@/helpers/analytics';

export default function googleAnalyticsTrack(payload) {
  try {
    window.dataLayer.push({
      ...analyticsDefaultPayload(),
      ...payload,
    });
  } catch {
    // Google Analytics may be blocked by browser extensions.
  }
}
