export function calculateAppointmentsCount(vouchers, property) {
  return (vouchers?.reduce((acc, curr) => {
    if (curr.is_unlimited) {
      return -1;
    }

    return acc + curr[property];
  }, 0) || 0);
}

export function getMembershipCoInsuranceAmount(promo) {
  return (Number(promo.percent_off) || 0) * 100;
}
