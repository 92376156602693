/* eslint-disable no-param-reassign */
export default {
  namespaced: true,

  state: {
    isLoading: false,
  },

  getters: {
    isLoading(state) {
      return state.isLoading;
    },
  },

  mutations: {
    setLoading(state, value) {
      state.isLoading = value;
    },
  },

  actions: {
    async verifyPasswordlessLoginEmail({ commit, rootState }, payload) {
      commit('setLoading', true);
      try {
        const response = await rootState.$auth.verifyPasswordlessLoginEmail({
          email: payload.email,
          channel: payload.channel,
        });
        commit('emailVerification/setVerificationData', response.data.data, { root: true });
      } finally {
        commit('setLoading', false);
      }
    },
  },
};
