import Vue, { reactive } from 'vue';

import Breakpoint from '@/enums/Breakpoint';

const breakpoints = {
  sm: Breakpoint.Small,
  md: Breakpoint.Medium,
  lg: Breakpoint.Large,
  xl: Breakpoint.ExtraLarge,
  '2xl': Breakpoint.DoubleExtraLarge,
};

const screen = reactive({
  width: window.innerWidth,
  height: window.innerHeight,
});

window.addEventListener('resize', () => {
  screen.width = window.innerWidth;
  screen.height = window.innerHeight;
});

Vue.prototype.$screen = (size) => {
  switch (size) {
    case 'tablet':
      return screen.width >= Breakpoint.Medium && screen.width < Breakpoint.Large;
    case 'desktop':
      return screen.width >= Breakpoint.Large;
    case 'mobile':
      return screen.width < Breakpoint.Medium;
    default:
      return screen.width >= breakpoints[size];
  }
};
