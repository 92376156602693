/* eslint-disable no-param-reassign */
export default {
  namespaced: true,
  state: {
    promoCodes: [],
    showNotification: true,
  },

  getters: {
    getPromoCodes(state) {
      return state.promoCodes;
    },
    hasAutoshipPromotion(state, getters) {
      return getters.getPromoCodes.some((promo) => promo.code.startsWith('FREEWITHAUTOSHIP-'));
    },
    primaryPromoCode(state) {
      return state.promoCodes && state.promoCodes[0];
    },
    activePromoCodes(state) {
      return state.promoCodes;
      // return state.promoCodes.filter((code) => code.times_redeemed === 0);
    },
    getShowNotification(state) {
      return state.showNotification;
    },
  },

  actions: {
    async fetchPromoCodes({ commit }) {
      try {
        const response = await this.$app.$api.get('v1/cx/promo-codes');

        commit('setPromoCodes', response.data.data);
      } catch {
        // consume
      }
    },
    async setupPromoCodes({ dispatch, commit, getters }) {
      if (sessionStorage.getItem('promoCodes')) {
        commit('setPromoCodes', JSON.parse(sessionStorage.getItem('promoCodes')));
      } else {
        await dispatch('fetchPromoCodes');
        sessionStorage.setItem('promoCodes', JSON.stringify(getters.getPromoCodes));
      }
    },
  },

  mutations: {
    setPromoCodes(state, data) {
      state.promoCodes = data;
    },
    setShowNotification(state, data) {
      state.showNotification = data;
    },
  },
};
