const getCloudflareAccessHeaders = () => {
  const clientId = import.meta.env.VITE_CF_TOKEN_ID;
  const secret = import.meta.env.VITE_CF_TOKEN_SECRET;

  if (clientId && secret) {
    return {
      'CF-Access-Client-Id': clientId,
      'CF-Access-Client-Secret': secret,
    };
  }

  return {};
};

// eslint-disable-next-line import/prefer-default-export
export { getCloudflareAccessHeaders };
