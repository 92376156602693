<script>
import trimStart from 'lodash/trimStart';
import { mapActions, mapGetters } from 'vuex';

import VetsterShineBorder from '@/components/VetsterShineBorder';
import useBrowserApiHelpers from '@/composables/use-browser-api-helpers';
import useCookies from '@/composables/use-cookies';
import SocialiteProvider from '@/enums/SocialiteProvider';
import { setCookie } from '@/helpers/cookies';
import { getAppUrl } from '@/helpers/url';
import ClickAway from '@/mixins/ClickAway';
import InteractsWithGlobalSearch from '@/mixins/InteractsWithGlobalSearch';
import InteractsWithHubSpot from '@/mixins/InteractsWithHubSpot';
import InteractsWithScroll from '@/mixins/InteractsWithScroll';
import ManagesClientSession from '@/mixins/ManagesClientSession';
import ManagesLocaleCookies from '@/mixins/ManagesLocaleCookies';
import googleAnalyticsTrack from '@/packages/googleAnalyticsTrack';

export default {
  name: 'VetsterNav',

  components: { VetsterShineBorder },

  mixins: [
    ClickAway,
    InteractsWithGlobalSearch,
    InteractsWithHubSpot,
    InteractsWithScroll,
    ManagesClientSession,
    ManagesLocaleCookies,
  ],

  props: {
    hasCtaExperiment: {
      type: Boolean,
      default: false,
    },
    localLinks: {
      type: [Array, Object],
      default: () => [],
    },
  },

  setup() {
    const { localStorageAvailable } = useBrowserApiHelpers();
    const { removeVetsterCookies } = useCookies();

    return {
      localStorageAvailable,
      removeVetsterCookies,
    };
  },

  data() {
    return {
      aboutOpen: false,
      profileOpen: false,
      forPetParentsOpen: false,
      forVetsAndTechsOpen: false,
      regionOpen: false,
      mobileOpen: false,
      mobileRegionOpen: false,
      currentlyOpenSideNavMenu: null,
      sideNavForPetsOpen: false,
      sideNavForVetsterAndTechsOpen: false,
      sideNavAboutOpen: false,
    };
  },

  computed: {
    ...mapGetters([
      'getUser',
      'hasUser',
      'isOnboarding',
      'isPartner',
      'isClient',
    ]),
    isGuest() {
      return !this.hasUser;
    },
  },

  watch: {
    mobileOpen(isOpen) {
      if (isOpen) {
        this.$nextTick(() => {
          this.$bus.$emit('disable-scroll');
          this.hideHubSpotIcon();
        });
      } else {
        this.$bus.$emit('enable-scroll');
        this.showHubSpotIcon();
      }
    },
  },

  created() {
    this.$store.commit('setLocalLinks', this.localLinks);
    this.$bus.$on('escape', this.handleEscape);
    this.$bus.$on('disable-scroll', this.disableScroll);
    this.$bus.$on('enable-scroll', this.enableScroll);
    this.checkAuthAndUser(true);
  },

  mounted() {
    document.addEventListener('click', (event) => {
      this.hideOnClickAway(
        event,
        this.$refs.profileMenu,
        this.closeProfileMenu,
        this.$refs.profileMenuButton,
      );
      this.hideOnClickAway(
        event,
        this.$refs.regionMenu,
        this.closeRegionMenu,
        this.$refs.regionMenuButton,
      );
      this.hideOnClickAway(
        event,
        this.$refs.mobileMenu,
        this.closeMobileMenu,
        this.$refs.mobileMenuButton,
      );
      this.hideOnClickAway(
        event,
        this.$refs.forPetParentsMenu,
        this.closeForPetParentsMenu,
        this.$refs.forPetParentsMenuButton,
      );
      this.hideOnClickAway(
        event,
        this.$refs.forVetsAndTechsMenu,
        this.closeForVetAndTechsMenu,
        this.$refs.forVetsAndTechsMenuButton,
      );
      this.hideOnClickAway(
        event,
        this.$refs.aboutMenu,
        this.closeAboutMenu,
        this.$refs.aboutMenuButton,
      );
    });
    window.addEventListener('resize', this.handleWindowResize);

    if (this.hasCtaExperiment) {
      this.$statsig.getExperiment('expt_search_navbar_cta_name');
    }
  },

  beforeDestroy() {
    this.$bus.$off('escape', this.handleEscape);
    this.$bus.$off('disable-scroll', this.disableScroll);
    this.$bus.$off('enable-scroll', this.enableScroll);
    window.removeEventListener('resize', this.handleWindowResize);
  },

  methods: {
    ...mapActions(['checkAuthAndUser']),

    ...mapActions('authModal', ['setAuthModalMode']),

    async dismissSlimBanner() {
      setCookie('v-slim-banner', true, '365d');
      document.querySelector('[data-testid="slim-banner"]')?.remove();
    },

    async signOut() {
      this.$root.toast(this.$t('login.signed-out-see-you-soon'));

      if (this.isGuest) {
        return true;
      }

      SocialiteProvider.values().forEach((provider) => {
        this.$store.dispatch('socialite/providerSdkLogout', {
          component: this,
          provider_type: provider.value,
        });
      });

      await this.$auth.signOut();

      this.$sentry.setUser(null);

      try {
        if (this.localStorageAvailable()) {
          window.localStorage.removeItem('userId');
        }
      } catch {
        //
      }

      this.removeVetsterCookies();

      this.clearSession();

      googleAnalyticsTrack(() => {
        this.reset();
      });
      this.$analytics.track({ event: 'logout' });
      window.location.href = '/';

      return true;
    },

    prefixedAppUrl(path) {
      if (!this.hasUser) {
        return getAppUrl('/');
      }

      const segment = this.isPartner ? 'px' : 'cx';

      if (this.isOnboarding) {
        return getAppUrl(`${segment}/welcome`);
      }

      return getAppUrl(`${segment}/${trimStart(path, '/')}`);
    },

    handleEscape() {
      this.closeProfileMenu();
      this.closeRegionMenu();
      this.closeMobileRegionMenu();
      this.closeMobileMenu();
      this.closeForPetParentsMenu();
      this.closeForVetAndTechsMenu();
      this.closeAboutMenu();
    },

    toggleAboutMenu() {
      this.aboutOpen = !this.aboutOpen;
    },

    toggleForPetParentsMenu() {
      this.forPetParentsOpen = !this.forPetParentsOpen;
    },

    toggleForVetsAndTechsMenu() {
      this.forVetsAndTechsOpen = !this.forVetsAndTechsOpen;
    },

    toggleRegionMenu() {
      this.regionOpen = !this.regionOpen;
    },

    toggleMobileRegionMenu() {
      this.mobileRegionOpen = !this.mobileRegionOpen;
    },

    toggleProfileMenu() {
      this.profileOpen = !this.profileOpen;
    },

    toggleMobileMenu() {
      this.mobileOpen = !this.mobileOpen;
      if (this.mobileOpen) {
        this.mobileRegionOpen = false;
      }
    },

    handleWindowResize() {
      // Check if the menu is open and the screen size is greater than lg
      if (this.mobileOpen && this.$screen('lg')) {
        // Close the menu and reset the style
        this.mobileOpen = false;
      }
    },

    toggleSideNavMenuOption(menuName) {
      // Toggle the clicked menu
      this[menuName] = !this[menuName];

      // Close other open menu if there is one
      if (this.currentlyOpenSideNavMenu && this.currentlyOpenSideNavMenu !== menuName) {
        this[this.currentlyOpenSideNavMenu] = false;
      }

      // Update the currentlyOpenMenu variable
      this.currentlyOpenSideNavMenu = this[menuName] ? menuName : null;
    },

    closeRegionMenu() {
      this.regionOpen = false;
    },

    closeMobileRegionMenu() {
      this.mobileRegionOpen = false;
    },

    closeForPetParentsMenu() {
      this.forPetParentsOpen = false;
    },

    closeForVetAndTechsMenu() {
      this.forVetsAndTechsOpen = false;
    },

    closeAboutMenu() {
      this.aboutOpen = false;
    },

    closeProfileMenu() {
      this.profileOpen = false;
    },

    closeMobileMenu() {
      this.mobileOpen = false;
    },

    openAuthModal(mode) {
      this.setAuthModalMode(mode);
      this.profileOpen = false;
    },

    async trackSlimBanner(e) {
      e.preventDefault();

      this.$statsig.logEvent('vfb_banner_click', null, {
        platform: 'cx-web',
        parent: 'landing-page',
        child: 'homepage',
      });
      googleAnalyticsTrack({
        event: 'ga_event',
        eventName: 'vfb_banner_click',
        platform: 'cx-web',
        parent: 'landing-page',
        child: 'homepage',
      });

      await this.dismissSlimBanner();

      window.location.href = e.target.href;
    },
  },
};
</script>
